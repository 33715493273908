import * as React from "react"
import PropTypes from "prop-types"

const Quote = ({ id, data }) => {
  return (
    <>
      <div id={id} className="section center-content">
        <figure>
          <blockquote>
            <p>{data.quoteText}</p>
          </blockquote>
          <figcaption>{data.author}</figcaption>
        </figure>
      </div>
      {data.picture ? (
        <div className="right-content">
          <img
            className="content-img"
            src={data.picture ? data.picture.url : ""}
            alt={"picture of " + data.author}
          />
        </div>
      ) : (
        ""
      )}
    </>
  )
}

Quote.propTypes = {
  data: PropTypes.object.isRequired,
}
export default Quote
