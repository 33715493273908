import * as React from "react"
import PropTypes from "prop-types"
import { withPrefix } from "gatsby"

const Title = ({ text, tinyTilteMobile }) => {
  return (
    <h1
      className={`title title_first ${
        tinyTilteMobile ? "smaller-mobile" : null
      }`}
    >
      <div className="title__cookie">
        {text.split("\n").map((str, i) => (
          <div key={i}>
            {str}
            <span className="filler"></span>
          </div>
        ))}
      </div>
      <video
        id="cookie"
        className="title-video"
        src={withPrefix("/cookie.webm")}
        muted
      ></video>
    </h1>
  )
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
}
export default Title
