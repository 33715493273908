import * as React from "react"
import PropTypes from "prop-types"

const SubtitleBig = ({ id, data }) => {
  return (
    <>
      <div id={id} className="section center-content">
        <h2 className="h2-double animate_text animate_text">
          {data.subtitleBig.split("\n").map((str, i) => (
            <div key={i}>
              {str}
              <span className="filler"></span>
            </div>
          ))}
        </h2>
      </div>
    </>
  )
}

SubtitleBig.propTypes = {
  data: PropTypes.object.isRequired,
}
export default SubtitleBig
