import * as React from "react"
import { useEffect } from "react"

import ReactMarkdown from "react-markdown"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Title from "../components/title_cookie"
import Audio from "../components/audio"
import Quote from "../components/quote"
import Text from "../components/text"
import Subtitle from "../components/subtitle"
import SubtitleBig from "../components/subtitle_big"

const SecondPage = data => {
  //   console.log(data)
  //   let pageContext, pageData
  let { pageContext } = data
  let { pageData } = pageContext
  let indexOfFirstQuote = pageData.repeater.findIndex(data =>
    data.hasOwnProperty("author")
  )

  // add sceneid
  const repeaterBeforeQuote = pageData.repeater.splice(0, indexOfFirstQuote)

  let pathname = ""
  const isBrowser = () => typeof window !== "undefined"
  if (isBrowser()) pathname = window.location.pathname
  // let nextPageData
  // nextPageData =
  //   pathname === "/"
  //     ? { slug: "seven_seconds_match", title: "Seven Seconds Match" }
  //     : pageContext.next

  // console.log(pageContext)
  // if (pageContext.next.pageTemplate === "introduction")
  //   nextPageData = { slug: "experts", title: "experts" }
  const nextPageData = pageContext.next
  let createNextPage = nextPageData ? (
    <Link className="h3" to={`${nextPageData.slug}`}>
      Next — Statement n°{pageContext.order + 1} <br />
      {nextPageData.title}
    </Link>
  ) : (
    ""
  )

  const prevPageData = pageContext.previous
  let createPrevPage = prevPageData ? (
    <Link className="h3" to={`${prevPageData.slug}`}>
      {pageContext.previous.pageTemplate === "introduction" ? (
        "Previous — introduction"
      ) : (
        <>
          Next — Statement n°{pageContext.order - 1} <br />
          {prevPageData.title}
        </>
      )}
    </Link>
  ) : (
    ""
  )
  // const mountedRef = useRef(true)
  import("../js/index")
    .then(m => {
      // if (!mountedRef.current) return null
      m.default()
    })
    .catch(err => {
      console.error("Error during loading module: " + err)
    })

  useEffect(() => {
    return () => {
      document.body.classList.remove("no-scroll-mobile")
      if (Array.isArray(window.allTimeouts))
        window.allTimeouts.forEach(id => clearTimeout(id))
    }
  }, [])

  return (
    <Layout siteTitle={pageData.title}>
      <Seo
        title={pageData.seo.title}
        keywords={pageData.seo.keywords}
        image={pageData.seo.image}
        description={pageData.seo.description}
      />
      <section className="content">
        <div className="left-content full-length">
          <canvas
            id={pageData.glb ? pageData.glb : "socials"}
            className="modelviewer"
          ></canvas>
        </div>
        <div id="scene1" className="section center-content">
          <Title
            text={pageData.title}
            tinyTilteMobile={pageData.tinyTilteMobile}
          />
          {pageData.audio ? (
            <Audio
              src={pageData.audio.audioFile ? pageData.audio.audioFile.url : ""}
              title={pageData.audio.title}
            />
          ) : (
            ""
          )}
        </div>
        {repeaterBeforeQuote.length !== 0 ? (
          <div id="scene2" className="section center-content">
            {repeaterBeforeQuote.map(function (key, i) {
              if (key.hasOwnProperty("text")) {
                return <ReactMarkdown key={i} children={key.text} />
              } else if (key.hasOwnProperty("subtitleBig")) {
                return <SubtitleBig key={i} data={key} />
              } else {
                return <Subtitle key={i} data={key} />
              }
            })}
          </div>
        ) : (
          ""
        )}
        {pageData.repeater.map(function (key, i) {
          let id =
            pageData.repeater.length - 1 == i && i !== 0 ? "scene3" : null
          id =
            i === 0 && repeaterBeforeQuote.length == 0 && id !== "scene3"
              ? "scene2"
              : id
          let props = { id: id, data: key }
          if (key.hasOwnProperty("author")) {
            return <Quote {...props} key={i} />
          } else if (key.hasOwnProperty("text")) {
            return (
              <div id={id} key={i} className="section center-content">
                <ReactMarkdown children={key.text} />
              </div>
            )
          } else if (key.hasOwnProperty("subtitleBig")) {
            return <SubtitleBig key={i} data={key} />
          } else {
            return <Subtitle {...props} key={i} />
          }
        })}
      </section>
      <div
        className="content"
        id={
          repeaterBeforeQuote.length === 0 && pageData.repeater.length < 2
            ? "scene3"
            : null
        }
      >
        <nav className="center-content pagination">
          {createPrevPage}
          {createNextPage}
        </nav>
      </div>
    </Layout>
  )
}

export default SecondPage
