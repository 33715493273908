import * as React from "react"
import PropTypes from "prop-types"

const Subtitle = ({ id, data }) => {
  return (
    <>
      <div id={id} className="section center-content">
        <h2 className="animate_text">
          <div>
            {data.subtitle} <span className="filler"></span>
          </div>
        </h2>
      </div>
    </>
  )
}

Subtitle.propTypes = {
  data: PropTypes.object.isRequired,
}
export default Subtitle
