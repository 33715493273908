import * as React from "react"
import PropTypes from "prop-types"

const Audio = ({ src, title }) => {
  return (
    <figure className="player">
      <h2 className="h3">audio</h2>
      <audio src={src} preload="auto"></audio>
      <div className="player-header">
        <div className="player-play"></div>
        <figcaption className="player-title">{title}</figcaption>
      </div>
      <span className="timeline">
        <span className="timeline-progress"></span>
        <span className="timeline-indicator"></span>
      </span>
      <div className="timings">
        <span className="timings-left">0:00</span>
        <span className="timings-right">-10:00</span>
      </div>
    </figure>
  )
}

Audio.propTypes = {
  src: PropTypes.string.isRequired,
}
export default Audio
